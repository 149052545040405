// Brand
$grey-dark: #4a4a4a
$java: #3d9398
$java-light: rgba($java, 0.8)
$red: #f14668
$white: #f5f5f5
$white-dark: #eeeeee

// Colours
$primary: $java
$background: $white
$gradient: linear-gradient(to right, $primary, #2d8da5 15%, #3785ae, #527bb0, #706ea9, #8066a1, #8d5d96, #985588, #9c5281, #9f4e7a, #a24c73, #a4496b)
$text: $grey-dark
$link: $text
$hover: $primary

// Spacing
$spacing: 0.75em
$spacing-largest: 1.25em
$spacing-large: 1em
$spacing-small: 0.5em
$spacing-smallest: 0.25em

// Transitions
$transition: background-color 0.5s ease, color 0.5s ease
$hover-in: 0.25s ease-in
$hover-out: 0.2s ease-out

// Shadows
$shadow: 0px 2px 4px rgba(51, 55, 59, 0.08),0px 1px 10px rgba(51, 55, 59, 0.06)
$shadow-heavy: 0px 4px 8px rgba(51, 55, 59, 0.08),0px 2px 20px rgba(51, 55, 59, 0.06)
$nav-shadow: 0px -2px 20px 0px
$footer-shadow: 0px 2px 20px 0px
$radius: 5px

// Typography
$family-sans-serif: "Nunito", sans-serif
$family-primary: $family-sans-serif

// Responsiveness
$gap: 32px !default
$tablet: 769px !default
$desktop: 960px + (2 * $gap) !default
$widescreen: 1152px + (2 * $gap) !default
$widescreen-enabled: true !default
$fullhd: 1344px + (2 * $gap) !default
$fullhd-enabled: true !default