.form
  margin: auto
  position: relative
  width: 500px
  z-index: 10
  .control
    display: flex
    margin-bottom: 2em
    position: relative
    text-transform: uppercase
    .border
      background: $primary
      height: 2px
      position: absolute
      top: 100%
      transform: scaleX(0)
      transform-origin:  0 50%
      transition: transform 0.5s
      width: 100%
    .label
      font-weight: bold
      left: 1.25em
      position: absolute
      top: 0.3em
      transition: all 0.2s
    .image
      position: absolute
      left: 1.25em
      top: 50%
      transform: translateY(-50%)
      &.name img
        height: 20px
  .button
    background: $gradient
    color: $white
    display: flex
    margin-left: auto
    padding: 1em 2em
    text-transform: uppercase
    position: relative
    span
      z-index: 10
    &:after
      transform-origin: 0 0
      z-index: 0
      position: absolute
      content: ""
      top: 0
      left: 0
      width: 100%
      transform: scaleX(0)
      background: $primary
      bottom: 0
      transition: all $hover-out
      border-radius: $radius
    &:hover:after
        transform: scaleX(100%)
  .textarea ~ .image
    top: 2.2em
  .button,
  .input,
  .textarea
    border: none
    border-radius: $radius
    box-shadow: $shadow
    font-size: 1.25em
    transition: $hover-in
  .input,
  .textarea
    background-color: $white-dark
    padding: 1em 1em 1em 2.75em
    width: 100%
    &.has-error ~ .border
      background-color: $red
    &:placeholder-shown
      ~ .label
        max-width: 66.66%
        overflow: hidden
        text-overflow: ellipsis
        transform: translate(2.2em, 1.47em) scale(1.25)
        transform-origin: left bottom
        white-space: nowrap
    &:focus,
    &:active,
    &:not(:placeholder-shown)
      border: none
      box-shadow: $shadow-heavy
      color: var(--colour)
      outline: none
      ~ .border
        transform: scaleX(1)
      ~ .label
        transform: translate(0, 0) scale(1)
    & ~ .is-left,
    &:focus ~ .is-left
      color: var(--colour) !important
    &::placeholder
      opacity: 0
