
          @import "@/assets/sass/variables.sass"
        

.navbar
  align-items: center
  background-color: #f5f5f5
  display: flex
  justify-content: space-between
  padding: 30px
  position: fixed
  width: 100%
  top: 0
  z-index: 30
  .container
    display: flex
