@import "./responsiveness"

$container-offset: (2 * $gap) !default
$container-max-width: $fullhd !default

.container
  flex-grow: 1
  margin: 0 auto
  position: relative
  width: auto
  &.is-fluid
    max-width: none !important
    padding-left: $gap
    padding-right: $gap
    width: 100%
  +desktop
    max-width: $desktop - $container-offset
  +until-widescreen
    &.is-widescreen:not(.is-max-desktop)
      max-width: min($widescreen, $container-max-width) - $container-offset
  +until-fullhd
    &.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen)
      max-width: min($fullhd, $container-max-width) - $container-offset
  +widescreen
    &:not(.is-max-desktop)
      max-width: min($widescreen, $container-max-width) - $container-offset
  +fullhd
    &:not(.is-max-desktop):not(.is-max-widescreen)
      max-width: min($fullhd, $container-max-width) - $container-offset
