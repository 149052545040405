
          @import "@/assets/sass/variables.sass"
        

.image
  flex-shrink: 0
  transition: border $hover-in
  &:hover
    border-color: $java-light !important
    transition: border $hover-out
  &.has-border
    background-color: var(--fill)
    border-style: solid
    border-width: 2px
    box-shadow: $shadow
    padding: 1px
