@import "../variables"

$body-background-color: $background !default
$body-size: 16px !default
$body-min-width: 300px !default
$body-rendering: optimizeLegibility !default
$body-family: $family-primary !default
$body-overflow-x: hidden !default
$body-overflow-y: scroll !default

$body-color: $text !default
$body-font-size: 1em !default
$body-weight: normal !default
$body-line-height: 1 !default

$small-font-size: 0.875em !default

html
  background-color: $body-background-color
  font-size: $body-size
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  min-width: $body-min-width
  overflow-x: $body-overflow-x
  overflow-y: $body-overflow-y
  text-rendering: $body-rendering
  text-size-adjust: 100%

[v-cloak]
  display: none

article,
aside,
figure,
footer,
header,
hgroup,
section
  display: block

body,
button,
input,
optgroup,
select,
textarea
  font-family: $body-family

body
  color: $body-color
  font-size: $body-font-size
  font-weight: $body-weight
  line-height: $body-line-height

// Inline
a
  color: $link
  cursor: pointer
  text-decoration: none
  transition: color $hover-in

  strong
    color: currentColor

  &.is-active,
  &:hover
    color: $hover
    transition: color $hover-out

img
  height: auto
  max-width: 100%

input[type="checkbox"],
input[type="radio"]
  vertical-align: baseline

small
  font-size: $small-font-size

span
  font-style: inherit
  font-weight: inherit

// Block
fieldset
  border: none

table
  td,
  th
    vertical-align: top

    &:not([align])
      text-align: inherit

.divider
  display: flex
  padding: 1em 0
  margin: auto
  position: relative
  width: 100%
  &:before
    height: 1px
    content: ""
    position: absolute
    width: 50%
    border-radius: 50%
    left: 50%
    transform: translateX(-50%)
    background-color: $grey-dark