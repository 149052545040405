
          @import "@/assets/sass/variables.sass"
        

.profile-icons
  align-items: center
  border-radius: $radius
  display: flex
  justify-content: space-around
  .image
    background-color: $primary
    border-radius: $radius
    box-shadow: $shadow-heavy
    transition: $hover-in
    &:hover
      filter: brightness(110%)
      transition: $hover-out
    :deep(img)
      border-radius: $radius
